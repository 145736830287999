<template>
    <div class="mx-auto ml-2 cotizador-zona">
        <v-row>
            <v-col cols="6">
                <div>
                    <b>{{ $t("general.Seleccione zona de marcaje") }}</b>

                    <v-select
                        v-model="zonaSeleccionada"
                        item-value="Zona"
                        :disabled="loadingCarrito"
                        :items="zonas"
                        label=""
                    >
                        <template slot="selection" slot-scope="data">
                            {{
                                $t("Zonas." + formatZona(data.item.Zona)) +
                                getDescriptionByZone(data.item.Posicion)
                            }}
                        </template>
                        <template slot="item" slot-scope="data">
                            {{
                                $t("Zonas." + formatZona(data.item.Zona)) +
                                getDescriptionByZone(data.item.Posicion)
                            }}
                        </template>
                    </v-select>
                </div>
                <div>
                    <v-row>
                        <v-col cols="12">
                            <div
                                class="d-flex flex-column align-center"
                                style="background-color: black"
                            >
                                <v-img
                                    v-show="!imgCustom"
                                    width="auto"
                                    class="mt-0"
                                    contain
                                    :src="imgUrl"
                                    :lazy-src="imgUrl"
                                    style="background-color: white"
                                    @error="errorImg = true"
                                ></v-img>
                                <div
                                    v-show="imgCustom"
                                    class="w-100"
                                    v-html="imgCustom"
                                ></div>
                                <DesignModal
                                    :id="id"
                                    :tecnica="tecnicaSeleccionada"
                                    :zona="zona"
                                    dark
                                ></DesignModal>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
            <v-col cols="6">
                <v-row>
                    <v-col class="pb-0 mb-3">
                        <div>
                            <b>
                                {{
                                    $t(
                                        "general.Seleccione una técnica para marcar"
                                    )
                                }}
                            </b>
                            <v-select
                                v-model="tecnicaSeleccionada"
                                :disabled="loadingCarrito"
                                :items="tecnicas"
                                item-text="nombre"
                                item-value="tecnica"
                                label=""
                                hide-details="true"
                                class="mb-3"
                            ></v-select>
                        </div>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="py-0 mb-3">
                        <v-expand-transition>
                            <v-card
                                v-if="
                                    tecnicaSeleccionada &&
                                    carrito.productos.length > 0
                                "
                                elevation="0"
                                color="transparent"
                            >
                                <div class="pb-0">
                                    <div
                                        v-if="tecnicaSeleccionada == 'Bordado'"
                                    >
                                        <Bordado
                                            :id="id"
                                            :zona="zona"
                                            :cantidad="cantidad"
                                        ></Bordado>
                                    </div>
                                    <div
                                        v-else-if="
                                            tecnicaSeleccionada == 'Serigrafia'
                                        "
                                    >
                                        <Serigrafia
                                            :id="id"
                                            :zona="zona"
                                            :cantidad="cantidad"
                                            :color="color"
                                        ></Serigrafia>
                                    </div>
                                    <div
                                        v-else-if="
                                            tecnicaSeleccionada ==
                                            'SerigrafiaNonWoven'
                                        "
                                    >
                                        <div>
                                            {{
                                                $t(
                                                    "configuracion.SerigrafíaNonWoven.Serigrafía Non Woven!"
                                                )
                                            }}
                                        </div>
                                    </div>
                                    <div
                                        v-else-if="
                                            tecnicaSeleccionada == 'Transfer'
                                        "
                                    >
                                        <Transfer
                                            :id="id"
                                            :zona="zona"
                                            :cantidad="cantidad"
                                            :color="color"
                                        ></Transfer>
                                    </div>
                                    <div
                                        v-else-if="
                                            tecnicaSeleccionada ==
                                            'ImpresionDirecta'
                                        "
                                    >
                                        <ImpresionDirecta
                                            :id="id"
                                            :zona="zona"
                                            :cantidad="cantidad"
                                            :color="color"
                                        ></ImpresionDirecta>
                                    </div>
                                    <div
                                        v-else-if="
                                            tecnicaSeleccionada ==
                                            'ImpresionVinilo'
                                        "
                                    >
                                        <ImpresionVinilo
                                            :id="id"
                                            :zona="zona"
                                            :cantidad="cantidad"
                                        ></ImpresionVinilo>
                                    </div>
                                    <div
                                        v-else-if="
                                            tecnicaSeleccionada ==
                                            'TransferEspeciales'
                                        "
                                    >
                                        <TransferEspeciales
                                            :id="id"
                                            :zona="zona"
                                            :cantidad="cantidad"
                                            :color="color"
                                        ></TransferEspeciales>
                                    </div>
                                    <!-- <div v-else-if="tecnicaSeleccionada == 'TransferNumeros'">
                <Numeros
                  :zona="zona"
                  :cantidad="cantidad"
                  :color="color"
                  :id="id"
                ></Numeros>
              </div> -->
                                    <div
                                        v-else-if="
                                            tecnicaSeleccionada == 'Sublimacion'
                                        "
                                    >
                                        <Sublimacion
                                            :id="id"
                                            :zona="zona"
                                            :cantidad="cantidad"
                                            :color="color"
                                        ></Sublimacion>
                                    </div>
                                    <div
                                        v-else-if="tecnicaSeleccionada == 'DTF'"
                                    >
                                        <DTF
                                            :id="id"
                                            :zona="zona"
                                            :cantidad="cantidad"
                                        ></DTF>
                                    </div>
                                    <div
                                        v-else-if="
                                            tecnicaSeleccionada == 'Numeros'
                                        "
                                    >
                                        <v-select
                                            v-model="selectedNumerosOption"
                                            :items="numerosTypeAvalible"
                                            label="Tipo de técnica para los números"
                                            color="primary darken-2"
                                            filled
                                            prepend-inner-icon="mdi-cog"
                                            hide-details="true"
                                            item-text="label"
                                            item-value="value"
                                            class="my-0 py-0"
                                        ></v-select>
                                        <template
                                            v-if="
                                                selectedNumerosOption == 'DTF'
                                            "
                                        >
                                            <DTFNumeros
                                                :id="id"
                                                :zona="zona"
                                            ></DTFNumeros>
                                        </template>
                                        <template
                                            v-else-if="
                                                selectedNumerosOption ==
                                                'Transfer'
                                            "
                                        >
                                            <TransferNumeros
                                                :id="id"
                                                :zona="zona"
                                            ></TransferNumeros>
                                        </template>
                                    </div>
                                    <div v-else>
                                        <CalculoPrecio
                                            :id="id"
                                            :zona="zona"
                                            :cantidad="cantidad"
                                            :tecnica="tecnicaSeleccionada"
                                            :color="color"
                                        ></CalculoPrecio>
                                    </div>
                                </div>
                            </v-card>
                        </v-expand-transition>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import uploaderImg from "../modales/uploadDesign.vue";
import Bordado from "../tecnicas/Bordado.vue";
import Serigrafia from "../tecnicas/Serigrafia.vue";
import Transfer from "../tecnicas/Transfer.vue";
import ImpresionDirecta from "../tecnicas/ImpresionDirecta.vue";
import CalculoPrecio from "../tecnicas/CalculoPrecio.vue";
import ImpresionVinilo from "../tecnicas/ImpresionVinilo.vue";
import TransferEspeciales from "../tecnicas/TransferEspeciales.vue";
// import Numeros from "../tecnicas/Numeros.vue";
import Sublimacion from "../tecnicas/Sublimacion.vue";
import DTF from "../tecnicas/DTF.vue";
import DesignModal from "../modales/designModal.vue";
import DTFNumeros from "../tecnicas/DTFNumeros.vue";
import TransferNumeros from "../tecnicas/TransferNumeros.vue";

import { noImgUrl } from "@/utils/imageUtils";

export default {
    name: "Zona",
    components: {
        Bordado,
        Serigrafia,
        Transfer,
        CalculoPrecio,
        ImpresionDirecta,
        ImpresionVinilo,
        TransferEspeciales,
        // Numeros,
        Sublimacion,
        // uploaderImg,
        DTF,
        DesignModal,
        DTFNumeros,
        TransferNumeros
    },
    props: {
        color: Object,
        zona: Object,
        cantidad: Number,
        activo: {
            type: Boolean,
            default: false
        },
        id: Number
    },
    data() {
        return {
            zonaSeleccionada: null,
            tecnicaSeleccionada: null,
            tecnicas: [],
            dialogo: false,
            mensajeDiseno: null,
            errorImg: false,
            defaultImg: noImgUrl,
            previewDesign: false,
            selectedNumerosOption: false
        };
    },
    computed: {
        ...mapGetters("carrito", [
            "carrito",
            "loadingCarrito",
            "totalUnidades",
            "savedDesign",
            "actualListDesign",
            "soloBlancoEnCarritoSublimacion"
        ]),
        ...mapGetters("modelo", [
            "modelPrice",
            "zonasDescription",
            "getFilteredTecnicas",
            "sublimacionStmInfo",
            "isReprocesosStm",
            "zonas",
            "modelInfoFromCatalog",
            "newComputedImgUrl",
            "transferStmTypeAvalible",
            "sublimacionSoloBlanco",
            "tecnicaRecomendada"
        ]),
        ...mapGetters("config", ["key", "colorSelected"]),
        imgCustom: function () {
            let design = this.actualListDesign.find(
                d => d.id == this.id && d.posicion == this.zona.Posicion
            );
            if (design && design.svg) return design.svg;
            return null;
        },
        imgUrl: function () {
            return this.newComputedImgUrl(this.zona, this.colorSelected);
        },
        coste: function () {
            let zona = this.zona.Zona;
            let precio = this.carrito.reprocesos.find(c => c.zona === zona);
            if (precio?.total) return precio?.total;

            return 0;
        },
        numerosTypeAvalible: function () {
            if (!this.tecnicas.some(t => t.tecnica == "Numeros")) return [];

            let numerosType = [];

            if (this.zona.Tecnicas.Dft)
                numerosType.push({
                    value: "DTF",
                    label: this.$t("Tecnicas.TRANSFER DIGITAL (DTF)")
                });
            if (this.zona.Tecnicas.Transfer)
                numerosType.push({
                    value: "Transfer",
                    label: this.$t("Tecnicas.TRANSFER")
                });

            return numerosType;
        }
    },
    methods: {
        ...mapActions("carrito", [
            "deleteReproceso",
            "updateNumeroReprocesos",
            "deleteBocetoRepeticion"
        ]),
        ...mapActions("modelo", ["updateImagenModelo"]),
        ...mapActions("config", ["updateHeightFrame"]),
        formatZona(value) {
            return value.toUpperCase().replace("_", " ");
        },
        getDescriptionByZone(zone) {
            let zoneDescription = this.zonasDescription.find(
                z => z.Posicion == zone
            );
            return zoneDescription?.Descripcion
                ? ` - ${zoneDescription.Descripcion}`
                : "";
        },
        tecnicasArray: function () {
            this.tecnicas = [];

            if (this.soloBlancoEnCarritoSublimacion) {
                if (this.zona.Tecnicas.Sublimacion) {
                    let id = "0";
                    let tecnica =
                        "Sublimacion" + (this.isReprocesosStm ? "Stm" : "");
                    let nombre = this.$t("Tecnicas.SUBLIMACIÓN");

                    this.tecnicas.push({
                        id: id,
                        tecnica: tecnica,
                        nombre: nombre
                    });

                    this.tecnicaSeleccionada = tecnica;
                }

                // Añadimos laser, ya que dicen que son dos técnicas que si son compatibles
                if (this.zona.Tecnicas.Laser) {
                    let id = "0";
                    let tecnica = "Laser";
                    let nombre = this.$t("Tecnicas.LASER");
                    this.tecnicas.push({
                        id: id,
                        tecnica: tecnica,
                        nombre: nombre
                    });
                }
                return this.tecnicas;
            }

            if (this.zona.Tecnicas.Serigrafia) {
                let id = "0";
                let tecnica =
                    "Serigrafia" + (this.isReprocesosStm ? "Stm" : "");
                let nombre = this.$t("Tecnicas.SERIGRAFÍA");
                this.tecnicas.push({
                    id: id,
                    tecnica: tecnica,
                    nombre:
                        nombre +
                        " (" +
                        this.zonasDescription.find(
                            z => z.Posicion == this.zona.Posicion
                        )?.Ancho +
                        "x" +
                        this.zonasDescription.find(
                            z => z.Posicion == this.zona.Posicion
                        )?.Alto +
                        " mm)"
                });
            }

            if (this.zona.Tecnicas.Transfer) {
                let id = "0";
                let tecnica = "Transfer" + (this.isReprocesosStm ? "Stm" : "");
                let nombre = this.$t("Tecnicas.TRANSFER");

                // Si es el Transfer de Stamina -> obtenemos el nombre de la técnica exacto si sólo tiene 1 activa
                if (tecnica == "TransferStm") {
                    let tecnicasTransfer = this.transferStmTypeAvalible(
                        this.zona.Posicion
                    );

                    if (tecnicasTransfer?.length == 1) {
                        nombre = this.$t(
                            "configuracion.CalculoPrecios." +
                                tecnicasTransfer[0]
                        );
                    }
                }

                if (this.modelPrice.infoReprocesos.AdvertenciaSerigrafia) {
                    nombre += ` (${this.$t("general.Recomendado")})`;
                }

                this.tecnicas.push({
                    id: id,
                    tecnica: tecnica,
                    nombre: nombre
                });

                if (
                    !this.isReprocesosStm &&
                    !this.tecnicas.some(t => t.tecnica == "Numeros")
                ) {
                    let id = "0";
                    let tecnica = "Numeros";
                    let nombre = this.$t("Tecnicas.NÚMEROS");
                    this.tecnicas.push({
                        id: id,
                        tecnica: tecnica,
                        nombre: nombre
                    });
                }
            }

            if (this.zona.Tecnicas.TransferEspeciales) {
                let id = "0";
                let tecnica = "TransferEspeciales";
                let nombre = this.$t("Tecnicas.TRANSFER ESPECIALES");
                this.tecnicas.push({
                    id: id,
                    tecnica: tecnica,
                    nombre: nombre
                });
            }
            if (this.zona.Tecnicas.ImpresionVinilica) {
                let id = "0";
                let tecnica = "ImpresionVinilo";
                let nombre = this.$t("Tecnicas.IMPRESIÓN VINILO");
                this.tecnicas.push({
                    id: id,
                    tecnica: tecnica,
                    nombre: nombre
                });
            }
            if (this.zona.Tecnicas.Sublimacion) {
                let id = "0";
                let tecnica =
                    "Sublimacion" + (this.isReprocesosStm ? "Stm" : "");
                let nombre = this.$t("Tecnicas.SUBLIMACIÓN");
                this.tecnicas.push({
                    id: id,
                    tecnica: tecnica,
                    nombre: nombre
                });
            }

            if (this.zona.Tecnicas.ImpresionDirecta) {
                let id = "0";
                let tecnica = "ImpresionDirecta";
                let nombre = this.$t("Tecnicas.IMPRESIÓN DIRECTA");
                this.tecnicas.push({
                    id: id,
                    tecnica: tecnica,
                    nombre: nombre
                });
            }

            if (this.zona.Tecnicas.ImpresionVinilica) {
                let id = "0";
                let tecnica = "ImpresionVinilo";
                let nombre = this.$t("Tecnicas.IMPRESIÓN VINILO");
                this.tecnicas.push({
                    id: id,
                    tecnica: tecnica,
                    nombre: nombre
                });
            }

            if (this.zona.Tecnicas.Bordado) {
                let id = "0";
                let tecnica = "Bordado" + (this.isReprocesosStm ? "Stm" : "");
                let nombre = this.$t("Tecnicas.BORDADOS");
                this.tecnicas.push({
                    id: id,
                    tecnica: tecnica,
                    nombre: nombre
                });

                // if (!this.isReprocesosStm) {
                //   let id2 = "0";
                //   let tecnica2 = "TransferNumeros";
                //   let nombre2 = this.$t("Tecnicas.NÚMEROS");
                //   this.tecnicas.push({
                //     id: id2,
                //     tecnica: tecnica2,
                //     nombre: nombre2,
                //   });
                // }
            }

            if (this.zona.Tecnicas.SerigrafiaNonWoven) {
                let id = "0";
                let tecnica = "SerigrafiaNonWoven";
                let nombre = this.$t("Tecnicas.BOLSAS NON WOVEN");
                this.tecnicas.push({
                    id: id,
                    tecnica: tecnica,
                    nombre: nombre
                });
            }

            if (this.zona.Tecnicas.Digital) {
                let id = "0";
                let tecnica = "Digital";
                let nombre = this.$t("Tecnicas.DIGITAL");
                this.tecnicas.push({
                    id: id,
                    tecnica: tecnica,
                    nombre: nombre
                });
            }

            if (this.zona.Tecnicas.Laser) {
                let id = "0";
                let tecnica = "Laser";
                let nombre = this.$t("Tecnicas.LASER");
                this.tecnicas.push({
                    id: id,
                    tecnica: tecnica,
                    nombre: nombre
                });
            }

            if (this.zona.Tecnicas.Dft) {
                let id = "0";
                let tecnica = "DTF";
                let nombre = this.$t("Tecnicas.TRANSFER DIGITAL (DTF)");
                if (this.modelPrice.infoReprocesos.AdvertenciaSerigrafia) {
                    nombre += ` (${this.$t("general.Recomendado")})`;
                }
                this.tecnicas.push({
                    id: id,
                    tecnica: tecnica,
                    nombre: nombre
                });

                if (
                    !this.isReprocesosStm &&
                    !this.tecnicas.some(t => t.tecnica == "Numeros")
                ) {
                    let id = "0";
                    let tecnica = "Numeros";
                    let nombre = this.$t("Tecnicas.NÚMEROS");
                    this.tecnicas.push({
                        id: id,
                        tecnica: tecnica,
                        nombre: nombre
                    });
                }
            }

            if (this.isReprocesosStm && this.zona.Tecnicas.Tampografia) {
                let id = "0";
                let tecnica = "Tampografia";
                let nombre = this.$t("Tecnicas.TAMPOGRAFÍA");
                this.tecnicas.push({
                    id: id,
                    tecnica: tecnica,
                    nombre:
                        nombre +
                        " (" +
                        this.zonasDescription.find(
                            z => z.Posicion == this.zona.Posicion
                        )?.Ancho +
                        "x" +
                        this.zonasDescription.find(
                            z => z.Posicion == this.zona.Posicion
                        )?.Alto +
                        " mm)"
                });
            }

            if (this.zona.Tecnicas.Termograbado) {
                let id = "0";
                let tecnica = "Termografia";
                let nombre = this.$t("Tecnicas.TERMOGRABADO");
                this.tecnicas.push({
                    id: id,
                    tecnica: tecnica,
                    nombre: nombre
                });
            }

            if (
                !this.modelPrice ||
                (this.zona && this.zona.Tecnicas.Cuatricomia)
            ) {
                let id = "0";
                let tecnica = "CuatricomiaStm";
                let nombre = this.$t("Tecnicas.CUATRICOMIA");
                this.tecnicas.push({
                    id: id,
                    tecnica: tecnica,
                    nombre: nombre
                });
            }

            if (!this.modelPrice || (this.zona && this.zona.Tecnicas.Doming)) {
                let id = "0";
                let tecnica = "DomingStm";
                let nombre = this.$t("Tecnicas.DOMING");
                this.tecnicas.push({
                    id: id,
                    tecnica: tecnica,
                    nombre: nombre
                });
            }

            if (this.tecnicas.length == 0) this.tecnicaSeleccionada = null;
            else {
                this.tecnicaSeleccionada = this.tecnicas.find(
                    t => t.tecnica == this.tecnicaSeleccionada
                )?.tecnica;

                if (
                    (this.tecnicaSeleccionada == "Serigrafia" ||
                        this.tecnicaSeleccionada == "SerigrafiaStm") &&
                    this.modelPrice.infoReprocesos.AdvertenciaSerigrafia &&
                    this.tecnicas.length > 1
                ) {
                    this.tecnicaSeleccionada = this.tecnicas.find(
                        t =>
                            t.tecnica != "Serigrafia" &&
                            t.tecnica != "SerigrafiaStm"
                    )?.tecnica;
                }

                if (!this.tecnicaSeleccionada)
                    this.tecnicaSeleccionada = this.tecnicas[0]?.tecnica;
            }

            //Comprobamos cual es la técnica recomendada para añadirle el texto de recomendado
            let indiceRecomendada = this.tecnicas.findIndex(t => t.tecnica == this.tecnicaRecomendada);
            if(this.tecnicaRecomendada != null && indiceRecomendada != -1 && !this.modelPrice.infoReprocesos.AdvertenciaSerigrafia)
                this.tecnicas[indiceRecomendada].nombre += ` (${this.$t("general.Recomendado")})`;
        },
        getTecnicaNombre: function (tecnica) {
            let resultado = this.tecnicas.find(t => t.tecnica == tecnica);
            if (resultado != undefined && resultado != null)
                return resultado.nombre;
            return "";
        }
    },
    watch: {
        loadingCarrito: function () {
            setTimeout(() => {
                this.updateHeightFrame();
            }, 100);
        },
        numerosTypeAvalible: function (newVal) {
            if (newVal.length > 0) this.selectedNumerosOption = newVal[0].value;
        },
        tecnicaSeleccionada: function (newTecnica, oldTecnica) {
            if (newTecnica == null)
                this.updateNumeroReprocesos({
                    a: -1,
                    p: this.zona.Zona,
                    t: newTecnica
                });
            else
                this.updateNumeroReprocesos({
                    a: 1,
                    p: this.zona.Zona,
                    t: newTecnica
                });
            if (oldTecnica != null)
                this.updateNumeroReprocesos({
                    a: -1,
                    p: this.zona.Zona,
                    t: oldTecnica
                });
            if (oldTecnica === newTecnica) return;
            this.deleteReproceso({
                modelo: this.carrito.modelo,
                zona: this.zona.Zona,
                id: this.id
            });
            
            
            this.deleteBocetoRepeticion(this.id);
            this.updateHeightFrame();
        },
        modelo: function (newModelo, oldModelo) {
            if (newModelo !== oldModelo) {
                this.tecnicasArray();
                let existeTecnicaEnNuevoModelo = this.tecnicas.filter(
                    t => t.tecnica === this.tecnicaSeleccionada
                );
                if (existeTecnicaEnNuevoModelo.length == 0) {
                    this.tecnicaSeleccionada =
                        this.tecnicas.length > 0
                            ? this.tecnicas[0].tecnica
                            : null;
                }
                this.errorImg = false;
                this.deleteBocetoRepeticion(this.id);
            }
        },
        zonaSeleccionada: function (newZona, oldZona) {
            if (oldZona == null) return;
            if (newZona !== oldZona || newZona == null) {
                this.tecnicasArray();
                this.errorImg = false;
                this.deleteReproceso({
                    modelo: this.carrito.modelo,
                    zona: oldZona,
                    id: this.id
                });
                this.errorImg = false;
                this.deleteBocetoRepeticion(this.id);

                // Emitimos zonaChange con la nueva zona
                this.$emit("zonaChange", newZona);
            }
        },
        soloBlancoEnCarritoSublimacion: function (newVal, oldVal) {
            if (newVal !== oldVal && oldVal && this.sublimacionSoloBlanco) {
                this.deleteReproceso({
                    modelo: this.carrito.modelo,
                    zona: this.zona.Zona,
                    id: this.id
                });
            }
        },
        zona: function (newZona, oldZona) {
            if (newZona !== oldZona) {
                this.zonaSeleccionada = newZona.Zona;
                this.tecnicasArray();
                if(this.tecnicaRecomendada != null && this.tecnicas.findIndex(t => t.tecnica == this.tecnicaRecomendada) != -1)
                    this.tecnicaSeleccionada = this.tecnicaRecomendada;
                else{
                    if (this.tecnicas.length > 0) {
                        this.tecnicaSeleccionada = this.tecnicas[0].tecnica;
                    }
                }
            }
        },
        modelPrice: "tecnicasArray",
        color: "tecnicasArray"
    },

    created() {
        this.zonaSeleccionada = this.zona?.Zona;
        this.tecnicasArray();
        //En el caso que tenga una tecnica recomendada y esté disponible para la zona seleccionada por defecto, la asignamos como la seleccionada, si no seleccionamos la primera
        if(this.tecnicaRecomendada != null && this.tecnicas.findIndex(t => t.tecnica == this.tecnicaRecomendada) != -1)
            this.tecnicaSeleccionada = this.tecnicaRecomendada;
        else{
            if (this.tecnicas.length > 0) {
                this.tecnicaSeleccionada = this.tecnicas[0].tecnica;
            }
        }
    },
    beforeDestroy() {
        this.deleteReproceso({
            modelo: this.carrito.modelo,
            zona: this.zona.Zona,
            id: this.id
        });
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.w-100 {
    width: 100%;
}
.cotizador-zona {
    border-radius: 0px;
    background-color: #f8f8f8;
    padding: 15px;
}
.logoright {
    float: right;
}
.logoleft {
    float: left;
}
.header {
    display: flex;
}
</style>
