<template>
  <div class="maximaAnchura">
    <v-container>
      <v-layout>
        <v-card color="white" class="black--text maximaAnchura">
          <section class="pdf-item">
            <v-container fill-height class="maximaAnchura">
              <v-layout>
                <v-flex>
                  <v-layout row>
                    <v-flex xs5>
                      <img
                        style="max-height: 100px"
                        class="styleImagenes"
                        :src="
                          MisPrecios.CustomLogo
                            ? MisPrecios.CustomLogoPath
                            : MisPrecios.logo
                        "
                        lazy-src="https://www.roly.es/pics/logo_roly.png"
                        :width="!MisPrecios.CustomLogo ? '250px' : ''"
                        contain
                      />
                    </v-flex>
                    <v-flex xs7 align="rigth" justify="rigth">
                      <v-card-title primary-title>
                        <div class="ml-7 anchuralinea_headline">
                          <div class="headline pdf-item">
                            <strong>
                              {{ $t("presupuesto.fichaReprocesos.titulop1") }}
                            </strong>
                            <span>{{ title }}</span>
                          </div>
                          <span class="urlweb">{{ carrito.modelo }}</span>
                        </div>
                      </v-card-title>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-container>
          </section>
          <section class="pdf-item">
            <v-container>
              <v-flex>
                <div>
                  <v-row>
                    <v-col cols="5" class="styleImagenes">
                      <img
                        v-if="getPrimeraZona.startsWith('http')"
                        :src="getPrimeraZona"
                        contain
                      />
                      <div
                        v-show="!getPrimeraZona.startsWith('http')"
                        v-html="getPrimeraZona"
                      ></div>
                      <div class="alternative-list">
                        <div
                          v-for="alternative in alternativesSVG"
                          :key="'alternative-svg-' + alternative.color"
                          class="images-alternative"
                        >
                          <div
                            v-if="alternative.svg"
                            v-html="alternative.svg"
                          ></div>
                          <img v-else :src="alternative.img" />
                        </div>
                      </div>
                    </v-col>
                    <v-col align="center">
                      <v-flex>
                        <v-card-text primary-title>
                          <v-row v-if="tieneDatosDeContacto">
                            <div class="div-resumen seccion">
                              <span>
                                {{
                                  $t(
                                    "presupuesto.fichaReprocesos.Datos de contacto"
                                  )
                                }}
                              </span>
                            </div>
                            <v-col>
                              <div id="divContacto" class="font-weight-regular">
                                <v-row justify="start" align="start">
                                  {{ MisPrecios.Empresa }}
                                </v-row>
                                <v-row justify="start" align="start">
                                  {{ MisPrecios.calle }}
                                </v-row>
                                <v-row justify="start" align="start">
                                  {{ MisPrecios.poblacion }}
                                  <span v-if="MisPrecios.cp">
                                    - {{ MisPrecios.cp }}
                                  </span>
                                </v-row>
                                <v-row justify="start" align="start">
                                  {{ MisPrecios.ciudad }}
                                  <span v-if="MisPrecios.pais">
                                    - {{ MisPrecios.pais }}
                                  </span>
                                </v-row>
                                <v-row justify="start" align="start">
                                  {{ MisPrecios.telefono }}
                                </v-row>
                                <v-row justify="start" align="start">
                                  {{ MisPrecios.correoContacto }}
                                </v-row>
                              </div>
                            </v-col>
                          </v-row>
                          <v-row>
                            <div class="div-resumen seccion">
                              <span>
                                {{ $t("presupuesto.fichaReprocesos.Resumen") }}
                              </span>
                            </div>
                            <v-col id="primeraColResumen">
                              <v-row class="margin-row">
                                <strong>
                                  {{ $t("presupuesto.fichaReprocesos.Modelo") }}
                                </strong>
                                <span
                                  class="span-resumen"
                                  v-html="$t(`MODELS.${carrito.modelo}.NAME`)"
                                ></span>
                              </v-row>
                              <v-row class="margin-row">
                                <strong>
                                  {{
                                    $t("presupuesto.fichaReprocesos.Prendas")
                                  }}
                                </strong>
                                <span class="span-resumen">
                                  {{ carrito.cantidad | filtrarPrecios }}
                                </span>
                              </v-row>
                              <v-row>
                                <strong>
                                  {{
                                    $t("presupuesto.fichaReprocesos.Tecnicas")
                                  }}
                                </strong>
                                <span class="span-resumen">
                                  {{ carrito.reprocesos.length }}
                                </span>
                              </v-row>
                            </v-col>
                            <v-col cols="4">
                              <div id="totalReprocesos" class="text-center">
                                <b>
                                  {{ $t("presupuesto.fichaReprocesos.Total") }}
                                </b>
                                {{ precioFinalS }}{{ currencySymbol }}
                              </div>
                            </v-col>
                          </v-row>
                          <v-row v-if="false">
                            <div class="div-resumen seccion">
                              <span>
                                {{ $t("presupuesto.Observaciones") }}
                              </span>
                            </div>
                            <v-col>
                              <v-row>
                                <v-col>
                                  <span>
                                    {{ MisPrecios.Observaciones ? MisPrecios.Observaciones : $t("presupuesto.ObservacionesCampo") }}
                                  </span>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col v-for="(value, key) in sortedReprocesosByIdWithNotes" v-show="value?.notes?.length > 0" :key="key" cols="6">
                                <span class="title-note">{{ generarNombreFromValue(value) }}</span>  
                                
                                <v-list class="py-0 list-notes" dense>
                                    <v-list-item v-for="(note, index) in value?.notes" :key="'note-summary-' + index" class="py-0 list-note-item">
                                      <v-list-item-content class="py-0">
                                        - {{  capitalizeFirstLetter(note.specialkey ? note.specialkey : $t("presupuesto.fichaReprocesos.params." + note.key)) }}: {{ note.value }}
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="py-0 list-note-item">
                                      <v-list-item-content class="py-0">
                                        - {{ getDesignByIdAndZone(value.id, value.params.posicion)?.observaciones }}
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list>
                            </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                          <v-row>
                            <div class="div-resumen2 seccion">
                              <span>
                                {{
                                  $t("presupuesto.fichaReprocesos.Productos")
                                }}
                              </span>
                            </div>
                            <tablaSimple
                              v-if="MostrarGanancias"
                              class="div-resumen2"
                              :headers="headers"
                              :productos-tabla="productosTabla()"
                              :referencia="titulostabla1"
                            />
                            <tablaSimple
                              v-else
                              class="div-resumen2"
                              :headers="headers2"
                              :productos-tabla="productosTabla()"
                              :referencia="titulostabla1"
                            />
                          </v-row>
                        </v-card-text>
                      </v-flex>
                    </v-col>
                  </v-row>
                </div>
              </v-flex>
            </v-container>
          </section>
          <div v-if="espdf" class="html2pdf__page-break" />
          <section class="pdf-item">
            <v-container>
              <v-flex>
                <div>
                  <v-row>
                    <v-col align="center" cols="5">
                      <v-flex>
                        <v-card-text primary-title>
                          <div class="div-resumen2 seccion">
                            <span>
                              {{ $t("presupuesto.fichaReprocesos.zonas") }}
                            </span>
                          </div>
                        </v-card-text>
                      </v-flex>
                      <div
                        v-for="(value, index) in getImagesZonasP"
                        :key="index"
                      >
                        <div
                          v-if="value.zona2 != null && value.zona2 != undefined"
                        >
                          <v-row>
                            <v-col cols="6" class="col-zonas">
                              <div
                                v-show="value.svg1"
                                class="svg-img"
                                v-html="value.svg1"
                              ></div>
                              <img
                                v-if="!value.svg1"
                                :src="value.zona1"
                                contain
                                class="col-md-12"
                              />
                              <div class="col-md-12 text-center">
                                {{ value.nombrezona1 }}
                              </div>
                            </v-col>
                            <v-col cols="6" class="col-zonas">
                              <div
                                v-show="value.svg2"
                                class="svg-img"
                                v-html="value.svg2"
                              ></div>
                              <img
                                v-if="!value.svg2"
                                :src="value.zona2"
                                contain
                                class="col-md-12"
                              />
                              <div class="col-md-12 text-center">
                                {{ value.nombrezona2 }}
                              </div>
                            </v-col>
                          </v-row>
                        </div>
                        <div v-else>
                          <v-row>
                            <v-col cols="6" class="col-zonas">
                              <div
                                v-show="value.svg1"
                                class="svg-img"
                                v-html="value.svg1"
                              ></div>
                              <img
                                v-if="!value.svg1"
                                :src="value.zona1"
                                contain
                                class="col-md-12"
                              />
                              <div class="col-md-12 text-center">
                                {{ value.nombrezona1 }}
                              </div>
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                    </v-col>
                    <v-col align="center">
                      <v-flex>
                        <v-card-text primary-title>
                          <div class="div-resumen2 seccion">
                            <span>
                              {{ $t("presupuesto.fichaReprocesos.tecncias") }}
                            </span>
                          </div>
                          <div
                            v-for="(value, key) in sortedReprocesosByIdWithNotes"
                            :key="key"
                          >
                            <tabla-simple
                              v-if="MostrarGanancias"
                              :headers="headers"
                              :productos-tabla="generarCamposReprocesos(value)"
                              :referencia="titulostabla1"
                              class="tabla-simple div-resumen2"
                            />
                            <tabla-simple
                              v-else
                              :headers="headers2"
                              :productos-tabla="generarCamposReprocesos(value)"
                              :referencia="titulostabla1"
                              class="tabla-simple div-resumen2"
                            />
                            <div v-if="value?.notes" class="d-flex align-start flex-column">
                              <i class="note"><b>{{$t('presupuesto.Observaciones')}}:&nbsp;</b>
                                <template v-for="(note, index) in value?.notes">
                                  {{  note.specialkey ? note.specialkey : $t("presupuesto.fichaReprocesos.params." + note.key) }}: {{ note.value }}
                                  <template v-if="index !== value.notes.length - 1">,&nbsp;</template>
                                </template>
                              </i>
                              <template v-if="(infoDesign = getDesignByIdAndZone(value.id, value.params.posicion)) && infoDesign.observaciones">
                                <i class="note"><b>{{$t('presupuesto.ObservacionesDiseno')}}:&nbsp;</b>{{infoDesign.observaciones}}</i>
                              </template>
                            </div>
                          </div>
                        </v-card-text>
                        <v-row
                          v-if="parseFloat(MisPrecios.costeExtra) > 0"
                          class="row-otros-costes"
                        >
                          <strong>{{ $t("presupuesto.OtrosCostes") }}:</strong>
                          <span class="span-resumen2">
                            {{ MisPrecios.costeExtra | filtrarPrecios
                            }}{{ currencySymbol }}
                          </span>
                        </v-row>
                      </v-flex>
                    </v-col>
                  </v-row>
                  <v-row class="row-otros-costes">
                    <strong>{{ $t("presupuesto.Observaciones") }}:</strong>
                    <span class="span-resumen2">
                      {{ MisPrecios.Observaciones }}
                    </span>
                    <span class="span-resumen2">
                      {{ $t("presupuesto.ObservacionesCampo") }}
                    </span>
                    <span class="span-resumen2-condiciones">
                      {{ $t("general.texto-condiciones") }}
                      <br />
                      {{ $t("general.texto-condiciones-aux") }}
                    </span>
                    <span
                      v-if="hasSublimacion"
                      class="span-resumen2-condiciones"
                    >
                      {{ $t("alert.alertSublimacionZonaNoBlanca") }}
                    </span>
                  </v-row>
                </div>
              </v-flex>
            </v-container>
          </section>
          <div
            v-if="espdf && carrito.reprocesos.length > 2"
            class="html2pdf__page-break"
          />
          <section v-if="MostrarGanancias" class="pdf-item">
            <v-container>
              <v-flex>
                <div align="center">
                  <v-flex>
                    <v-card-text primary-title>
                      <div class="div-resumen2 seccion">
                        <span>
                          {{ $t("presupuesto.fichaReprocesos.Resumenprecios") }}
                        </span>
                      </div>
                    </v-card-text>
                  </v-flex>
                  <v-row>
                    <v-col></v-col>
                    <v-col>
                      {{ $t("presupuesto.fichaReprocesos.Compra") }}
                    </v-col>
                    <v-col>
                      {{ $t("presupuesto.fichaReprocesos.Tuprecio") }}
                    </v-col>
                    <v-col>
                      {{ $t("presupuesto.fichaReprocesos.Ganancia") }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col align-left>
                      {{ $t("presupuesto.fichaReprocesos.PrecioProductos") }}
                    </v-col>
                    <v-col align-right>
                      {{ precioProductosS | filtrarPrecios
                      }}{{ currencySymbol }}
                    </v-col>
                    <v-col align-right>
                      {{ precioProductos | filtrarPrecios }}{{ currencySymbol }}
                    </v-col>
                    <v-col align-right>
                      {{ (precioProductos - precioProductosS) | filtrarPrecios
                      }}{{ currencySymbol }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col align-left>
                      {{ $t("presupuesto.fichaReprocesos.PrecioReprocesos") }}
                    </v-col>
                    <v-col align-right>
                      {{ precioReprocesosS | filtrarPrecios
                      }}{{ currencySymbol }}
                    </v-col>
                    <v-col align-right>
                      {{ precioReprocesos | filtrarPrecios
                      }}{{ currencySymbol }}
                    </v-col>
                    <v-col align-right>
                      {{
                        (precioReprocesos - precioReprocesosS) | filtrarPrecios
                      }}{{ currencySymbol }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col align-right>
                      {{ $t("presupuesto.fichaReprocesos.PrecioTotal") }}
                    </v-col>
                    <v-col align-right>
                      {{ precioFinalS | filtrarPrecios }}{{ currencySymbol }}
                    </v-col>
                    <v-col align-right>
                      {{ precioFinal | filtrarPrecios }}{{ currencySymbol }}
                    </v-col>
                    <v-col align-right>
                      {{ (precioFinal - precioFinalS) | filtrarPrecios
                      }}{{ currencySymbol }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <div class="col-md-6 col-md-offset-2 text-center ganancia">
                      <br />
                      <b class="span-resumen2">
                        {{ $t("presupuesto.fichaReprocesos.GanaciaUnidad") }}
                      </b>
                      {{ gananciaPorUnidad | filtrarPrecios
                      }}{{ currencySymbol }}
                    </div>
                  </v-row>
                </div>
              </v-flex>
            </v-container>
          </section>
        </v-card>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import tablaSimple from "../tablas/tablaClientSideNoPaging.vue";
import { tryGenerateMergeZones, setBackgroundToSvg } from "@/utils/fabricUtils.js";
import { isEmpty } from "lodash";
import { noImgUrl } from "@/utils/imageUtils";

export default {
  components: {
    tablaSimple
  },
  data() {
    return {
      headers: [
        {
          text: this.titulostabla()[0],
          align: "start",
          sortable: false,
          value: "Referencia",
          class: "grey darken-2 white--text"
        },
        {
          text: this.titulostabla()[1],
          align: "start",
          sortable: false,
          value: "Nombre",
          class: "grey darken-2 white--text"
        },
        {
          text: this.titulostabla()[2],
          align: "start",
          sortable: false,
          value: "Precio",
          class: "grey darken-2 white--text"
        },
        {
          text: this.titulostabla()[3],
          align: "start",
          sortable: false,
          value: "Cantidad",
          class: "grey darken-2 white--text"
        },
        {
          text: this.titulostabla()[6],
          align: "start",
          sortable: false,
          value: "Total",
          class: "grey darken-2 white--text"
        },
        {
          text: this.titulostabla()[5],
          align: "start",
          sortable: false,
          value: "MiPrecio",
          class: "grey darken-2 white--text"
        }
      ],
      headers2: [
        {
          text: this.titulostabla()[0],
          align: "start",
          sortable: false,
          value: "Referencia",
          class: "grey darken-2 white--text"
        },
        {
          text: this.titulostabla()[1],
          align: "start",
          sortable: false,
          value: "Nombre",
          class: "grey darken-2 white--text"
        },
        {
          text: this.titulostabla()[3],
          align: "start",
          sortable: false,
          value: "Cantidad",
          class: "grey darken-2 white--text"
        },
        {
          text: this.titulostabla()[2],
          align: "start",
          sortable: false,
          value: "Precio",
          class: "grey darken-2 white--text"
        },
        {
          text: this.titulostabla()[4],
          align: "start",
          sortable: false,
          value: "Total",
          class: "grey darken-2 white--text"
        }
      ],
      svgPreview: ""
    };
  },
  computed: {
    ...mapGetters("carrito", [
      "carrito",
      "preciosPrendas",
      "preciosReprocesos",
      "filteredListDesignInCart",
      "numDesignForEachPosition",
      "filteredListDesignInCart",
      "listColorInCart",
      "numDesignForEachPreviewRule",
      "previewGlobalDesign",
      "sortedReprocesosByIdWithNotes",
      "getDesignByIdAndZone"
    ]),
    ...mapGetters("modelo", [
      "codigosModelos",
      "modelo",
      "modelos",
      "productos",
      "currencySymbol",
      "modelPrice",
      "isReprocesosStm",
      "modelInfoFromCatalog",
      "zonas",
      "newComputedImgUrl"
    ]),
    ...mapGetters("config", ["translateTecnica", "colorSelected"]),
    alternativesSVG: function () {
      if (this.listColorInCart.length == 0) return [];

      // Nos quedamos todos los colores excepto el que ya mostramos
      let aux = this.listColorInCart.filter(c => c != this.colorSelected);
      console.log("🚀 ~ aux:", aux)

      let result = [];

      let primaryImageSvg =
        this.getPrimeraZona?.svg1 ??
        this.getPrimeraZona?.svg2 ??
        this.getPrimeraZona ??
        null;

      if (
        this.numDesignForEachPosition == null ||
        isEmpty(this.numDesignForEachPosition) ||
        primaryImageSvg == null
      ) {
        // Buscamos la zona que usar (la misma que la foto principal)
        aux.forEach(c => {
          let colores = this.modelInfoFromCatalog.image_views.find(
            u => u.color == c
          );
          if (colores != null) {
            result.push({ color: c, img: colores.url });
          }

          // Si se quisiese hacer la imagen de una zona, deberíamos usar la función ya existente
          // computedListImages[c] = this.computedImgUrl(actualZone, c);
        });
        return result;
      }

      // Buscamos que zona hemos usado para el diseño conjunto
      let avalibleMultipleDesign = this.previewGlobalDesign;

      if (avalibleMultipleDesign == null || (!avalibleMultipleDesign.hasPreviewRule && !avalibleMultipleDesign.hasZoneDesign)) return [];
      let actualZone = avalibleMultipleDesign.zones[0];

      // Sustituimos cada uno de los elementos de computedListImages a por el svg, haciendo el cambio de fondo, por el calculado
      aux.forEach(c => {
        
        let svg = primaryImageSvg;
        const newFondo = this.newComputedImgUrl(actualZone, c, true);
        svg = setBackgroundToSvg(primaryImageSvg, newFondo)
        result.push({ color: c, svg: svg });
      });

      return result;
    },
    hasSublimacion: function () {
      return this.carrito.reprocesos.some(
        r => r.tecnica.includes("Sublimacion") && !this.isReprocesosStm
      );
    },
    precioReprocesosS: function () {
      var precio = 0.0;
      this.carrito.reprocesos.forEach(element => {
        element.lineas.forEach(linea => {
          precio = precio + parseFloat(linea.total);
        });
      });
      return precio;
    },
    precioProductosS: function () {
      var precio = 0.0;
      this.carrito.productos.forEach(element => {
        precio = precio + parseFloat(element.total);
      });
      return precio;
    },
    precioFinalS: function () {
      var precio = 0.0;
      if (this.MostrarGanancias)
        precio = this.precioReprocesosS + this.precioProductosS;
      else
        precio =
          this.precioReprocesos +
          this.precioProductos +
          parseFloat(this.MisPrecios.costeExtra);
      return precio.toFixed(2);
    },
    precioReprocesos: function () {
      var precio = 0.0;
      this.carrito.reprocesos.forEach(element => {
        element.lineas.forEach(linea => {
          precio =
            precio +
            (parseFloat(linea.total) +
              (parseFloat(linea.total) * this.MisPrecios.porcentajeReprocesos) /
                100);
        });
      });
      return precio;
    },
    precioProductos: function () {
      var precio = 0.0;
      this.carrito.productos.forEach(element => {
        precio =
          precio +
          (parseFloat(element.total) +
            (parseFloat(element.total) * this.MisPrecios.porcentajeProductos) /
              100);
      });
      return precio;
    },
    precioFinal: function () {
      var precio =
        parseFloat(this.precioReprocesos) +
        parseFloat(this.precioProductos) +
        parseFloat(this.MisPrecios.costeExtra);
      return precio;
    },
    gananciaPorUnidad: function () {
      var precio = this.precioFinal - this.precioFinalS;
      var cantidad = 0.0;
      this.carrito.productos.forEach(element => {
        cantidad = cantidad + parseFloat(element.cantidad);
      });
      return precio / cantidad;
    },

    tieneDatosDeContacto: function () {
      return (
        this.MisPrecios.Empresa != "" ||
        this.MisPrecios.calle != "" ||
        this.MisPrecios.poblacion != "" ||
        this.MisPrecios.cp != "" ||
        this.MisPrecios.ciudad != "" ||
        this.MisPrecios.pais != "" ||
        this.MisPrecios.telefono != "" ||
        this.MisPrecios.correoContacto != ""
      );
    },
    getPrimeraZona() {
      let defaultImg = noImgUrl;

      if (this.svgPreview) return this.svgPreview;

      if (
        this.filteredListDesignInCart.length > 0 &&
        this.getImagesZonasP.some(d => d.svg1 || d.svg2)
      ) {
        let design = this.getImagesZonasP.find(d => d.svg1 || d.svg2);

        if (design && design.svg1) return design.svg1;
        else if (design && design.svg2) return design.svg2;
      }
      if (this.carrito && this.colorSelected && this.modelInfoFromCatalog) {
        let resultImg;
        let colores = this.modelInfoFromCatalog.image_views.find(
          u => u.color == this.colorSelected
        );
        if (colores) {
          resultImg = colores.url;
        } else {
          this.newComputedImgUrl(this.zonas[0], this.colorSelected, true);
        }

        if (resultImg && resultImg != defaultImg) {
          return resultImg;
        }

        console.warn(
          "No hemos podido encontrar una imagen sin cuadrado, vamos a intentar a usar la imagen con cuadrado"
        );
        resultImg = this.newComputedImgUrl(this.zonas[0], this.colorSelected);

        if (resultImg && resultImg != defaultImg) {
          return resultImg;
        }
      }
      return defaultImg;
    },
    getImagesZonasP() {
      const arrzonas = [];
      let lastZona = {};
      let myindex = 0;

      this.carrito.reprocesos
        .filter(
          r =>
            !r.tecnica.includes("Manipulacion") &&
            !r.tecnica.includes("Etiquetas") &&
            !r.tecnica.includes("Planchado") &&
            !r.isExtraCantidadExacta
        )
        .slice()
        .reverse()
        .sort((a, b) => a.id - b.id)
        .forEach(element => {
          const zonaActual = this.zonas.find(
            elem => elem.Zona === element.zona
          );

          let defaultImg = noImgUrl;

          if (
            this.modelInfoFromCatalog &&
            this.modelInfoFromCatalog.image_print &&
            this.carrito &&
            this.colorSelected
          ) {
            const zonaName = this.$i18n.t(
              "Zonas." + zonaActual.Zona.toUpperCase().replace("_", " ")
            );

            var imgCustom = this.imgCustom(element.id, zonaActual);

            if (!imgCustom)
              defaultImg = this.newComputedImgUrl(
                zonaActual,
                this.colorSelected
              );

            if (myindex === 0) {
              lastZona.zona1 = defaultImg;
              lastZona.nombrezona1 = zonaName;
              lastZona.svg1 = imgCustom;
            } else {
              lastZona.zona2 = defaultImg;
              lastZona.nombrezona2 = zonaName;
              lastZona.svg2 = imgCustom;
              arrzonas.push({ ...lastZona });
              lastZona = new Object();
            }

            myindex = 1 - myindex; // Alternar entre 0 y 1
          }
        });

      if (myindex === 1) {
        arrzonas.push(lastZona);
      }
      return arrzonas;
    }
  },
  props: {
    load: {
      type: Boolean,
      default: true,
      cargando: false
    },
    espdf: {
      type: Boolean,
      default: true,
      cargando: false
    },
    title: {
      type: String,
      default: "Presupuesto",
      cargando: false
    },
    titulostabla1: {
      type: String,
      default: "Referencia",
      cargando: false
    },
    titulostabla2: {
      type: String,
      default: "Nombre",
      cargando: false
    },
    titulostabla3: {
      type: String,
      default: "Precio",
      cargando: false
    },
    titulostabla4: {
      type: String,
      default: "Cantidad",
      cargando: false
    },
    titulostabla5: {
      type: String,
      default: "Total",
      cargando: false
    },
    titulostabla6: {
      type: String,
      default: "PVP",
      cargando: false
    },
    titulostabla7: {
      type: String,
      default: "Coste",
      cargando: false
    },
    MisPrecios: {
      type: Object,
      default: null,
      cargando: false
    },
    MostrarGanancias: {
      type: Boolean,
      default: true,
      cargando: false
    }
  },
  watch: {
    getImagesZonas: function () {
      return this.getImagesZonasP;
    },
    titulostabla: function () {
      return this.titulostabla();
    },
    load: async function (newVal) {
      if (newVal) {
        this.svgPreview = await tryGenerateMergeZones(
          this.numDesignForEachPosition,
          this.numDesignForEachPreviewRule,
          this.filteredListDesignInCart
        );
      }
    }
  },
  async beforeMount() {
    this.svgPreview = await tryGenerateMergeZones(
      this.numDesignForEachPosition,
      this.numDesignForEachPreviewRule,
      this.filteredListDesignInCart
    );
  },
  methods: {
    capitalizeFirstLetter(value) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    titulostabla() {
      return new Array(
        this.titulostabla1,
        this.titulostabla2,
        this.titulostabla3,
        this.titulostabla4,
        this.titulostabla5,
        this.titulostabla6,
        this.titulostabla7
      );
    },
    generarNombreFromValue(value) {
      return !value.zona ? this.translateTecnica(value.tecnica) :
          this.translateTecnica(value.tecnica) +
          "-" +
          this.$i18n.t("Zonas." + value.zona.toUpperCase().replace("_", " "));
    },
    generarCamposReprocesos(value) {
      var arr = new Array();
      if (this.MostrarGanancias) {
        var defecto = new Object();
        defecto.Referencia = "";
        defecto.Nombre = this.generarNombreFromValue(value);
        defecto.Precio = "";
        defecto.Cantidad = "";
        defecto.Total = "";
        defecto.MiPrecio = "";
        arr.push(defecto);
        value.lineas.forEach(element => {
          var obj = new Object();
          obj.Referencia = element.id;
          obj.Nombre = element.nombre;
          obj.Precio =
            element.precio.toLocaleString(this.$i18n.locale, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 4
            }) + this.currencySymbol;

            if(element.multiplicador && element.multiplicador > 1)
            {
              obj.Precio = `${element.multiplicador}x${obj.Precio}`;
            }
          obj.Cantidad = element.cantidad;
          obj.Total =
            element.total.toLocaleString(this.$i18n.locale, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }) + this.currencySymbol;
          obj.MiPrecio =
            (
              parseFloat(element.total) +
              (parseFloat(element.total) *
                this.MisPrecios.porcentajeReprocesos) /
                100
            ).toLocaleString(this.$i18n.locale, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }) + this.currencySymbol;
          arr.push(obj);
        });
      } else {
        var precio = 0.0;
        var defecto2 = new Object();
        defecto2.Referencia = "";
        defecto2.Nombre = this.generarNombreFromValue(value);
        defecto2.Precio = "";
        defecto2.Cantidad = "";
        defecto2.Total = "";
        defecto2.MiPrecio = "";
        arr.push(defecto2);
        value.lineas.forEach(element => {
          var obj = new Object();
          obj.Referencia = element.id;
          obj.Nombre = element.nombre;
          precio =
            element.precio +
            (parseFloat(element.precio) *
              this.MisPrecios.porcentajeReprocesos) /
              100;
          obj.Precio =
            precio.toLocaleString(this.$i18n.locale, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 4
            }) + this.currencySymbol;

            if(element.multiplicador && element.multiplicador > 1)
            {
              obj.Precio = `${element.multiplicador}x${obj.Precio}`;
            }
          obj.Cantidad = element.cantidad;
          obj.Total =
            element.total.toLocaleString(
              this.$i18n.locale,
              { minimumFractionDigits: 2, maximumFractionDigits: 4 }
            ) + this.currencySymbol;
          arr.push(obj);
        });
      }
      return arr;
    },
    productosTabla() {
      var prods = new Array();
      this.carrito.productos.forEach(element => {
        let name = this.modelPrice?.productTranslation?.find(
          p => p.code == element.id
        )?.translate;
        if (this.MostrarGanancias) {
          var obj = new Object();
          obj.Referencia = element.id;
          let talla = this.modelPrice.tallasInfo.find(
            t => t.Talla == element.talla
          );
          obj.Nombre =
            name ??
            this.$i18n.t(`MODELS.${this.carrito.modelo}.NAME`) +
              " + " +
              talla?.TallaName ??
            element.talla + " + " + this.$i18n.t(`COLORS.${element.color}`);
          obj.Precio =
            element.precio.toLocaleString(this.$i18n.locale, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 4
            }) + this.currencySymbol;
          obj.Cantidad = element.cantidad;
          let realTotal = parseFloat(element.total);
          obj.Total =
            realTotal.toLocaleString(this.$i18n.locale, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }) + this.currencySymbol;
          obj.MiPrecio =
            (
              realTotal +
              (parseFloat(element.total) *
                this.MisPrecios.porcentajeProductos) /
                100
            ).toLocaleString(this.$i18n.locale, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }) + this.currencySymbol;
          prods.push(obj);
        } else {
          var precio = 0.0;
          var obj2 = new Object();
          obj2.Referencia = element.id;
          let talla = this.modelPrice?.tallasInfo?.find(
            t => t.Talla == element.talla
          );
          obj2.Nombre =
            name ??
            this.$i18n.t(`MODELS.${this.carrito.modelo}.NAME`) +
              " + " +
              (talla?.TallaName ?? element.talla) +
              " + " +
              this.$i18n.t(`COLORS.${element.color}`);

          precio =
            element.precio +
            element.precio * (this.MisPrecios.porcentajeProductos / 100);
          // precio = Math.round(precio * 1000) / 1000
          obj2.Precio =
            precio.toLocaleString(this.$i18n.locale, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 4
            }) + this.currencySymbol;
          obj2.Cantidad = element.cantidad;

          obj2.Total =
            parseFloat(precio * obj2.Cantidad).toLocaleString(
              this.$i18n.locale,
              { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            ) + this.currencySymbol;
          prods.push(obj2);
        }
      });

      return prods;
    },
    imgCustom: function (id, zona) {
      let design = this.getDesignByIdAndZone(id, zona.Posicion)
      if (design && design.svg) return design.svg;
      return null;
    }
  }
};
</script>
<style lang="scss" scoped>
  .title-note {
    text-decoration: underline;
    width: 100%;
    text-transform: uppercase;
    font-size: 16px;
  }
.list-notes {
  .list-note-item {
    padding: 0 !important;
    min-height: 24px !important;
    text-align: start;
    
  }
}

.alternative-list {
  max-width: 500px;
  display: flex;
  flex-wrap: wrap;

  .images-alternative {
    max-width: 120px;

    img {
      max-width: 120px;
    }
  }
}

.divIzquierda {
  text-align: left;
}
.maximaAnchura {
  width: 100% !important;
}
.styleImagenes {
  margin-left: 30px;
}
.styleImagenes > img {
  width: 90%;
}
.headline > strong {
  font-size: 45px !important;
}
.headline > span {
  font-size: 45px !important;
}
#tituloPresupuesto {
  margin-top: 7px;
}
#divContacto {
  padding-bottom: 30px;
  padding-top: 30px;
  font-family: Times new roman;
  font-size: 20px;
}
.div-resumen {
  border-top: solid 1px;
  border-bottom: solid 1px;
  width: 100%;
}
.div-resumen2 {
  border-top: solid 1px;
  border-bottom: solid 1px;
  width: 100%;
  margin-bottom: 10px;
}
.span-resumen {
  font-size: 16px !important;
  margin-left: 5px;
}
.span-resumen2 {
  font-size: 18px !important;
  margin-left: 5px;
}
.span-resumen2-condiciones {
  font-size: 18px !important;
  color: rgb(192, 44, 44);
}
#primeraColResumen {
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 15px;
  font-family: Arial;
}
#totalReprocesos {
  background-color: black;
  color: white;
  font-weight: bold;
  height: 100%;
  padding-bottom: 20px;
  font-size: 15px;
  font-family: Arial;
  padding-top: 50px;
}
.tabla-simple {
  margin-top: 20px;
}
.col-zonas {
  max-width: 50% !important;
}
.col-zonas > div {
  vertical-align: middle;
  background-color: #7e7e7e;
  font-weight: bold;
  color: white;
  padding: 13px 10px 13px 10px;
}
.row-otros-costes {
  margin-left: 2%;
  margin-top: 3%;
  display: flex;
  align-items: flex-end;
}
.ganancia {
  background-color: grey;
  color: white;
  font-weight: bold;
  height: 100%;
  padding-bottom: 20px;
}
.seccion {
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}
strong {
  font-size: 20px;
}
.margin-row {
  margin-bottom: 3px;
}

.anchuralinea_headline {
  line-height: 160% !important;
}

.svg-img {
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
}

.note {
  text-align: left;
}
</style>
